<template>
  <div
    class="relative w-full h-full"
    :class="
      currentContract.id || currentPolls.length > 0 || subaccountContract.id
        ? 'overflow-hidden'
        : 'overflow-auto'
    "
    ref="scroller"
  >
    <div
      v-if="toast"
      @click="toast = false"
      class="rounded-lg text-white font-bold px-6 py-3 max-w-96 fixed bottom-5 right-5 z-50 transition-transform transform translate-y-0"
      :class="
        toastStatus == 'success'
          ? 'bg-green-600'
          : toastStatus == 'warning'
          ? 'bg-orange-300'
          : 'bg-red-500'
      "
    >
      {{ toast }}
    </div>
    <div
      v-if="subaccountContract.id"
      class="absolute w-full h-full text-center top-0 left-0 bg-black/50"
    >
      <div class="bg-white rounded-lg p-4 w-1/2 mt-2 text-left inline-block">
        <font-awesome-icon
          icon="xmark"
          class="text-3xl text-primary cursor-pointer float-right m-3"
          @click="subaccountContract = {}"
        />
        <span class="text-2xl font-bold">Unteraccount verwalten</span><br />
        <span class="mb-4 inline-block"
          >Die hier eingetragene E-Mail Adresse hat nach dem Login ebenfalls
          Zugriff auf diesen Vertrag. Die einzigen zur Verfügung stehenden
          Funktionen für diesen Vertrag sind die Auswahl von Essen und die
          Nutzung der digitalen Mensakarte.<br /><b
            >Falls für diese Mailadresse kein Social Login (Google / Apple
            /Microsoft) möglich ist, muss sich der Unteraccount vor dem ersten
            Anmeldung bei ev-angel-isch registrieren, danach kann Ev-Angel-Isch als Anmeldeoption genutzt werden.</b
          ></span
        >
        <label>E-Mail-Adresse</label>
        <input v-model="subaccountContract.Unteraccount" placeholder="E-Mail" />
        <div class="w-full text-left mt-3">
          <button class="full" @click="updateSubaccount()">Speichern</button>
        </div>
      </div>
    </div>
    <BookingPopup
      v-if="currentContract.id"
      :currentContract="currentContract"
      :readonly="true"
      @toast="showToast"
      @close="currentContract = {}"
    />
    <PollPopup
      v-if="currentPolls.length > 0"
      :polls="currentPolls"
      :contract="pollContract"
      @toast="showToast"
      @close="currentPolls = [];window.history.pushState(null, null, '/services')"
    />
    <div
      v-if="contracts.length == 0"
      class="font-bold m-4 p-4 rounded-lg bg-white"
    >
      <span
        >Sie haben bisher keine Leistungen gebucht. Buchen Sie ihre erste
        Leistung <a href="/book" class="text-primary underline">hier</a>.</span
      >
    </div>
    <div v-else class="p-4">
      <div
        v-for="contract in contracts"
        :key="contract"
        class="bg-white rounded-lg p-4 mb-4 mr-4 border-primary border inline-block align-top cursor-pointer w-96 shadow-lg"
        :class="{ '!bg-gray-200': !contract.active }"
      >
        <span class="font-bold text-lg h-14 inline-block">{{ contract.Titel }}</span>
        <div class="">
          <div>
            <span
              ><b>Status: </b
              ><span
                class="font-bold"
                :class="{
                  'text-red-500': ['Gekündigt','Widerrufen','Absage'].includes(contract.Status),
                  'text-green-500': ['Bestätigt'].includes(contract.Status),
                  'text-yellow-500': [
                    'Eingegangen',
                    'Warteliste',
                    'Vorgemerkt',
                    'Kündigung eingegangen',
                  ].includes(contract.Status),
                }"
                >{{ contract.Status }}</span
              ></span
            ><br />
            <span><b>Kind: </b>{{ contract.Vorname }}</span
            ><br />
            <span><b>Start: </b>{{ contract.Vertragsbeginn }}</span
            ><br />
            <span v-if="contract.Vertragsende"
              ><b>Ende:</b> {{ contract.Vertragsende }}</span
            >
            <span v-else><b>Ende:</b> Offen</span>
            <div class="float-right text-right pt-4">
              <button
                v-if="
                  ['Bestätigt', 'Gekündigt'].includes(contract.Status) &&
                  contract.poll
                "
                class=""
                @click="
                  scrollToTop(() => {
                    viewPoll(contract);
                  })
                "
              >
                Umfrage beantworten
              </button>
              <button
                class=""
                @click="
                  scrollToTop(() => {
                    selectContract(contract);
                  })
                "
              >
                Details
              </button>
              <button
                v-if="
                  ['Bestätigt', 'Gekündigt'].includes(contract.Status) &&
                  contract.subaccount
                "
                @click="
                  scrollToTop(() => {
                    subaccountContract = contract;
                  })
                "
              >
                Unteraccount
              </button>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
import BookingPopup from "@/components/BookingPopup.vue";
import PollPopup from "@/components/PollPopup.vue";
export default {
  name: "Mealplanning",
  data() {
    return {
      toast: "",
      toastStatus: false,
      customerdata: {},
      contracts: [],
      currentContract: {},
      currentPolls: [],
      newContract: {},
      pollContract: {},
      subaccountContract: {},
      window:window
    };
  },
  components: {
    PollPopup,
    BookingPopup,
  },
  methods: {
    scrollToTop(runAfter) {
      this.$refs.scroller.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(runAfter, 500);
    },
    getCustomerData() {
      API.getCustomerData((r, status) => {
        if (status === 200) {
          this.customerdata = r;
        }
      });
    },
    updateSubaccount() {
      API.updateContract(this.subaccountContract, (r, status) => {
        if (status === 200) {
          this.showToast("Unterkonto aktualisiert");
          this.getContracts();
          this.subaccountContract = {};
        } else if (r.error) {
          this.showToast(r.error, false);
        } else {
          this.showToast("Unterkonto konnte nicht aktualisiert werden", false);
        }
      });
    },
    getContracts() {
      API.getContracts((r, status) => {
        if (status === 200) {
          this.contracts = r;
          if(this.$route.query.pollContract){
            this.viewPoll(this.contracts.find(c => c.id == this.$route.query.pollContract));
          }
        }
      });
    },
    selectContract(contract) {
      this.currentContract = contract;
    },
    viewPoll(contract) {
      this.pollContract = contract;
      window.history.pushState(null, null, '/services?pollContract=' + contract.id);
      API.getPollsForContract(contract.id, (r, status) => {
        if (status === 200) {
          this.currentPolls = r;
        }
      });
    },
    showToast(msg, status = "success") {
      this.toast = msg;
      this.toastStatus = status;
      if (this.toastTimer) {
        clearTimeout(this.toastTimer);
      }
      this.toastTimer = setTimeout(() => {
        this.toast = "";
      }, 10000);
    },
  },
  created() {
    this.getCustomerData();
    this.getContracts();
  },
};
</script>