<template>
  <div class="relative w-full h-full overflow-y-auto p-4">
    <div
      v-if="toast"
      class="rounded-lg text-white font-bold px-6 py-3 w-56 fixed bottom-5 right-5 z-50 transition-transform transform translate-y-0"
      :class="
        toastStatus == 'success'
          ? 'bg-green-600'
          : toastStatus == 'warning'
          ? 'bg-orange-300'
          : 'bg-red-500'
      "
    >
      {{ toast }}
    </div>
    <div class="bg-white rounded-lg p-4 w-full" v-if="customerdata">
      <span class="font-bold text-xl">Kontaktdaten</span><br />
      <div class="grid md:grid-cols-2">
        <div class="p-2">
          <span class="text-lg font-bold"
            >Erziehungsberechtigter 1 (Hauptaccount):</span
          >
          <label>Vorname (Pflichtfeld)</label>
          <input
            :class="
              validateField('VornameErziehungsberechtigter') ? 'bg-red-500' : ''
            "
            v-model="customerdata.VornameErziehungsberechtigter"
            placeholder="Vorname"
          />
          <label>Nachname (Pflichtfeld)</label>
          <input
            :class="
              validateField('NachnameErziehungsberechtigter')
                ? 'bg-red-500'
                : ''
            "
            v-model="customerdata.NachnameErziehungsberechtigter"
            placeholder="Nachname"
          />
          <label>E-Mail (Pflichtfeld)</label>
          <input
            v-model="customerdata.EmailEltern"
            placeholder="E-Mail"
            disabled
            class="bg-gray-300 font-bold"
          />
          <label>Mobilnummer (Pflichtfeld)</label>
          <input
            :class="validateField('MobilnummerEltern') ? 'bg-red-500' : ''"
            v-model="customerdata.MobilnummerEltern"
            placeholder="Mobilnummer"
          />
          <label>Festnetz</label>
          <input v-model="customerdata.Festnetz" placeholder="Festnetz" />
          <div class="w-2/3 inline-block pr-4">
            <label>Straße</label>
            <input
              v-model="customerdata.AdresseBestaetigt"
              class="hidden"
              id="validated"
            />
            <input
              :class="validateField('Strasse') ? 'bg-red-500' : ''"
              v-model="customerdata.Strasse"
              id="street"
              placeholder="Straße"
            />
          </div>
          <div class="w-1/3 inline-block">
            <label>Hausnr.</label>
            <input
              :class="validateField('Hausnummer') ? 'bg-red-500' : ''"
              v-model="customerdata.Hausnummer"
              placeholder="Hausnr."
            />
          </div>
          <div class="w-1/3 inline-block pr-4">
            <label>Postleitzahl</label>
            <input
              :class="validateField('Postleitzahl') ? 'bg-red-500' : ''"
              v-model="customerdata.Postleitzahl"
              placeholder="Postleitzahl"
            />
          </div>
          <div class="w-2/3 inline-block">
            <label>Stadt</label>
            <input
              :class="validateField('Ort') ? 'bg-red-500' : ''"
              v-model="customerdata.Ort"
              placeholder="Stadt"
            />
          </div>
          <input
            type="checkbox"
            id="Alleinerziehend"
            v-model="customerdata.Alleinerziehend"
          />
          <label for="Alleinerziehend">Alleinerziehend</label>
          <input
            type="checkbox"
            id="KeineInfomails"
            v-model="customerdata.KeineInfomails"
          />
          <label for="KeineInfomails"
            >Ich möchte KEINE Infomails zu weiteren Angeboten der ev-angel-isch
            gGmbH erhalten!</label
          >
        </div>
        <div class="p-2" v-if="!customerdata.Alleinerziehend">
          <span class="text-lg font-bold"
            >Erziehungsberechtigter 2 (Für Infomails und Notfälle):</span
          >
          <label>Vorname</label>
          <input
            v-model="customerdata.VornameErziehungsberechtigter2"
            placeholder="Vorname"
          />
          <label>Nachname</label>
          <input
            v-model="customerdata.NachnameErziehungsberechtigter2"
            placeholder="Nachname"
          />
          <label>E-Mail</label>
          <input v-model="customerdata.EmailEltern2" placeholder="E-Mail" />
          <label>Mobilnummer</label>
          <input
            v-model="customerdata.MobilnummerEltern2"
            placeholder="Mobilnummer"
          />
        </div>
      </div>
      <div class="w-full text-left mt-2">
        <button class="full" @click="saveData('contact')">Speichern</button>
      </div>
    </div>
    <div class="bg-white rounded-lg p-4 w-full mt-4">
      <span class="font-bold text-xl">Bankdaten</span><br />

      <div class="grid md:grid-cols-2">
        <div class="pr-2">
          <label>Abweichender Kontoinhaber</label>
          <input
            v-model="customerdata.AbwKontoinhaber"
            placeholder="Kontoinhaber"
          />
        </div>
        <div class="pr-2">
          <label>Geburtsdatum (für SEPA-Mandate, Pflichtfeld)</label>
          <input
            type="date"
            class="md:w-1/2"
            :class="validateField('Geburtsdatum') ? 'bg-red-500' : ''"
            v-model="customerdata.Geburtsdatum"
            placeholder="01.03.1990"
          />
        </div>
        <div class="pr-2">
          <label>IBAN (Pflichtfeld)</label>
          <input
            v-model="customerdata.IBAN"
            placeholder="IBAN"
            :class="
              validation &&
              (!customerdata.IBAN || customerdata.IBAN.length == 0)
                ? 'bg-red-500'
                : ''
            "
          />
        </div>
        <div class="pr-2">
          <label>BIC</label>
          <input v-model="customerdata.BIC" placeholder="BIC" />
        </div>
      </div>
      <div class="w-full text-left mt-2">
        <button class="full" @click="saveData('bank')">Speichern</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Mealplanning",
  data() {
    return {
      requests: [],
      contracts: [],
      currentContract: {},
      toast: "",
      toastStatus: false,
      singleDay: {},
      customerdata: {},
      validation: false,
      bankfields: ["IBAN", "Geburtsdatum"],
      contactfields: [
        "Strasse",
        "Hausnummer",
        "VornameErziehungsberechtigter",
        "NachnameErziehungsberechtigter",
        "Ort",
        "Postleitzahl",
        "MobilnummerEltern",
      ],
    };
  },

  methods: {
    getCustomerData() {
      API.getCustomerData((r, status) => {
        if (status === 200) {
          this.customerdata = r;
        }
      });
    },
    validateField(field) {
      if (this.bankfields.includes(field)) {
        return (
          this.validation &&
          (!this.customerdata[field] || this.customerdata[field].length == 0)
        );
      } else if (this.contactfields.includes(field)) {
        return (
          this.validation &&
          (!this.customerdata[field] || this.customerdata[field].length == 0)
        );
      }
    },
    showToast(msg, status = "success") {
      this.toast = msg;
      this.toastStatus = status;
      if (this.toastTimer) {
        clearTimeout(this.toastTimer);
      }
      this.toastTimer = setTimeout(() => {
        this.toast = "";
      }, 10000);
    },
    saveData(mode) {
      let valid = true;
      this.validation = true;
      this.bankfields.forEach((field) => {
        if (this.validateField(field)) {
          valid = false;
        }
      });
      this.contactfields.forEach((field) => {
        if (this.validateField(field)) {
          valid = false;
        }
      });

      if (!valid) {
        this.showToast("Bitte alle Pflichtfelder ausfüllen", false);
        return;
      }
      API.saveCustomerData(this.customerdata, (r, status) => {
        if (status === 200) {
          this.showToast("Daten gespeichert");
        } else if (r.error) {
          this.showToast(r.error, false);
        } else {
          this.showToast("Daten konnten nicht gespeichert werden", false);
        }
      });
    },
  },
  mounted() {
    this.getCustomerData();
    const input = document.getElementById("street");
    console.log(input);
    const autocomplete = new google.maps.places.Autocomplete(input, {
      fields: ["address_components"],
      location: { lat: 50.937892, lng: 6.945366 },
      radius: 100000,
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        this.customerdata.AdresseBestaetigt = true;
        place.address_components.forEach((c) => {
          if (c.types.includes("street_number"))
            this.customerdata.Hausnummer = c.long_name;
          if (c.types.includes("route"))
            this.customerdata.Strasse = c.long_name;
          if (c.types.includes("locality")) this.customerdata.Ort = c.long_name;
          if (c.types.includes("postal_code"))
            this.customerdata.Postleitzahl = c.long_name;
        });
      }
      console.log(place); // Process the place data as needed
    });
  },
};
</script>