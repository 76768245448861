<template>
  <div class="relative w-full h-full overflow-y-auto p-4">
    <div v-if="!currentContract.id" class="bg-white rounded-lg p-4">
      <span class="font-bold"
        >Sie haben aktuell keine Verträge, bei denen eine Essensauswahl möglich
        ist. Sie können hier also nichts tun.</span
      >
    </div>
    <div v-if="currentContract.id">
      <div
        class="bg-black/50 fixed top-20 left-56 right-0 h-full text-center p-10"
        v-if="editedMeal"
      >
        <div
          class="bg-white rounded-lg p-4 max-h-full overflow-y-auto inline-block relative text-left"
        >
          <div
            class="absolute top-5 right-5 p-2 cursor-pointer"
            @click="editedMeal = false"
          >
            <font-awesome-icon icon="xmark" class="text-2xl" />
          </div>
          <span class="font-bold text-xl w-full text-center"
            >Essen anlegen</span
          >
          <label>Hauptgericht</label>
          <textarea
            class="border-2 border-gray-200 px-2 py-1 w-96"
            v-model="editableMeal"
            placeholder="Gericht"
            @input="updateMeals()"
          ></textarea
          ><br />
          <span class="font-bold">Fleischart: </span>
          <select v-model="editableMealType" class="my-2">
            <option value="Vegetarisch">Vegetarisch</option>
            <option value="Geflügel">Geflügel</option>
            <option value="Fisch">Fisch</option>
            <option value="Rind">Rind</option>
            <option value="Schwein">Schwein</option>
            <option value="Lamm">Lamm</option>
          </select>
          <label>Variationen (mit "/" getrennt)</label>
          <input
            placeholder="Mit Vollkorn/Ohne Vollkorn"
            class="border-2 border-gray-200 px-2 py-1 w-96"
            v-model="editableVariation"
          />
          <div class="w-full mt-2">
            <button class="full" @click="saveMeal()">Speichern</button>
          </div>
          <div class="">
            <div
              v-for="meal in freemeals"
              @click="
                editableMeal = meal;
                updateMeals();
              "
              :key="meal"
              class="cursor-pointer border-2 border-gray-200 inline-block shadow-lg px-2 py-1 m-1"
            >
              {{ meal }}
            </div>
            <div
              v-for="meal in usedmeals"
              @click="
                editableMeal = meal;
                updateMeals();
              "
              :key="meal"
              class="cursor-pointer border-2 border-gray-200 bg-gray-500 inline-block shadow-lg px-2 py-1 m-1"
            >
              {{ meal }}
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white rounded-lg py-4 px-4 w-full">
        <span class="font-bold text-xl">Erklärung:</span>
        <br />
        <span
          >Wählen Sie zuerst den gewüschten Vertrag aus. Anschließend werden die Kalenderwochen vorgeschlagen, für den ein Essensplan verfügbar ist. Wählen Sie nun die gewünschte Woche aus und klicken Sie auf "Woche bearbeiten". Nach der Auswahl der Gerichte speichern Sie die Auswahl mit "Woche speichern".</span>

      </div>
      <div class="bg-white rounded-lg py-4 pl-4 w-full mt-4">
        <span class="font-bold text-xl">Essensbestellung für Vertrag:</span
        ><br />
        <div
          v-for="contract in contracts"
          :key="contract.id"
          class="mr-4 shadow-lg border-4 rounded-lg my-2 px-2 pb-2 text-center inline-block align-top cursor-pointer"
          :class="{ 'border-green-500': currentContract == contract }"
          @click="selectContract(contract)"
        >
          <span class="font-bold text-lg whitespace-nowrap">{{
            contract.project
          }}</span>
          <div class="w-full text-left">
            <span><b>Name: </b>{{ contract.name }}</span
            ><br />
            <span><b>Laufzeit: </b>{{ contract.runtime }}</span>
          </div>
        </div>
        <div v-if="currentContract && currentContract.project.includes('Monte')">
          <a href="https://www.ev-angel-isch.de/monte-verde/#speiseplan" target="_blank" class="underline">Weitere Infos und Allergene</a>
        </div>
        <div v-if="currentContract && currentContract.project.includes('Apostel')">
          <a href="https://www.ev-angel-isch.de/apostel-mensa/#speiseplan" target="_blank" class="underline">Weitere Infos und Allergene</a>
        </div>
        <label>Kalenderwoche</label>

        <button
          v-for="weekNr in Object.keys(availableWeeks)"
          :key="weekNr"
          class="font-bold px-2 py-1 border-gray-400 border-2 rounded-lg mr-2 mt-2 inline-block hover:border-green-200 hover:bg-transparent hover:text-black cursor-pointer text-black"
          :class="{ '!border-green-400': weekNr == selectedWeek }"
          @click="setWeek(weekNr)"
        >
          KW {{ weekNr }} ({{ getWeekDates(weekNr) }})
        </button>
      </div>

      <div
        v-if="Object.keys(availableWeeks).length == 0"
        class="bg-white rounded-lg p-4 mt-4 font-bold inline-block w-full"
      >
        <span>Keine Essenspläne für diesen Vertrag verfügbar</span>
      </div>
      <div v-if="selectedWeek">
        <div
          class="bg-white rounded-lg py-4 px-4 w-full mt-2"
          v-if="Object.keys(selectedMealPlan).length > 0"
        >
          <span class="font-bold text-xl"
            >KW {{ selectedWeek }} ({{ getWeekDates(selectedWeek) }})</span
          >
          <div
            v-for="day in Object.keys(selectedMealPlan)"
            :key="day"
            class="border-b border-black"
          >
            <div v-if="currentContract[day]">
              <span class="font-bold"
                >{{ day }}, {{ getWeekDateForWeekday(selectedWeek, day) }}</span
              >
              <div v-if="selectedMealPlan[day].closed">
                <span class="inline-block ml-10 py-2 font-bold"
                  >Mensa geschlossen:
                  {{ selectedMealPlan[day].closingReason }}</span
                >
              </div>
              <div v-else-if="!currentContract.allergy">
                <div
                  v-for="meal, index in selectedMealPlan[day].meals"
                  :key="meal"
                  class="inline-block"
                >
                  <div
                    class="shadow-lg p-2 rounded-xl m-2 cursor-pointer max-w-72 inline-block border-2"
                    :class="
                      selectedMealPlan[day].choices.main &&
                      selectedMealPlan[day].choices.main === meal.main
                        ? 'border-green-600'
                        : ''
                    "
                    v-if="
                      meal.main != '' &&
                      (editMode ||
                        (selectedMealPlan[day].choices.main &&
                          selectedMealPlan[day].choices.main === meal.main))
                    "
                  >
                    <div
                      class="w-full h-full flex flex-row"
                      @click="chooseMeal(day, meal, index)"
                    >
                      <font-awesome-icon
                        v-if="meal.mainType === 'Schwein'"
                        icon="piggy-bank"
                        class="text-secondary align-top mt-2 pr-2 text-4xl"
                      />
                      <font-awesome-icon
                        v-else-if="meal.mainType === 'Rind'"
                        icon="cow"
                        class="text-secondary align-top mt-2 pr-2 text-4xl"
                      />
                      <font-awesome-icon
                        v-else-if="meal.mainType === 'Fisch'"
                        icon="fish-fins"
                        class="text-secondary align-top mt-2 pr-2 text-4xl"
                      />
                      <font-awesome-icon
                        v-else-if="meal.mainType === 'Geflügel'"
                        icon="drumstick-bite"
                        class="text-secondary align-top mt-2 pr-2 text-4xl"
                      />
                      <img
                        src="@/assets/lamb.png"
                        v-else-if="meal.mainType === 'Lamm'"
                        class="text-secondary align-top mt-1 pr-2 w-12"
                      />
                      <font-awesome-icon
                        v-else-if="meal.mainType === 'Vegetarisch'"
                        icon="leaf"
                        class="text-secondary align-top mt-2 pr-2 text-4xl"
                      />
                      <font-awesome-icon
                        v-else
                        icon="utensils"
                        class="text-secondary align-top mt-2 pr-2 text-4xl"
                      />
                      <div class="inline-block">
                        <div class="border-b border-gray-700">
                          <span
                            class="font-bold"
                            @click="chooseMeal(day, meal)"
                            >{{ meal.main }}</span
                          >
                        </div>
                        <div>
                          <div v-if="meal.variations.length > 0" class="pt-2">
                            <div
                              v-for="variation, index in meal.variations"
                              :key="variation"
                              class="inline-block"
                            >
                              <div
                                v-if="
                                  editMode ||
                                  selectedMealPlan[day].choices.variation ==
                                    variation
                                "
                                @click="chooseVariation(day, variation, index)"
                                :class="
                                  selectedMealPlan[day].choices.main &&
                                  selectedMealPlan[day].choices.main ===
                                    meal.main &&
                                  selectedMealPlan[day].choices.variation ==
                                    variation
                                    ? 'border-green-600'
                                    : ''
                                "
                                class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                              >
                                {{ variation }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  v-if="selectedMealPlan[day].meals[0].main == ''"
                  class="inline-block ml-10"
                  >Keine Gerichte auswählbar</span
                >
                <div
                  class="inline-block shadow-lg p-2 rounded-xl m-2 cursor-pointer align-top"
                  v-if="selectedMealPlan[day].desserts.length > 0"
                >
                  <font-awesome-icon
                    icon="ice-cream"
                    class="text-secondary align-top mt-2 text-4xl"
                  />
                  <div class="inline-block">
                    <div>
                      <div class="pt-2">
                        <div
                          v-for="dessert in selectedMealPlan[day].desserts"
                          :key="dessert"
                          class="inline-block"
                        >
                          <div
                            v-if="
                              editMode ||
                              selectedMealPlan[day].choices.dessert === dessert
                            "
                            class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                            :class="
                              selectedMealPlan[day].choices.dessert === dessert
                                ? 'border-green-500'
                                : ''
                            "
                            @click="chooseDessert(day, dessert)"
                          >
                            {{ dessert }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <span class="text-red-500 font-bold"
                  >Allergikeressen (automatisch ausgewählt)</span
                >
              </div>
            </div>
          </div>
          <div v-if="!currentContract.allergy" class="mt-4 w-full text-center">
            <button
              class="w-96 border-red-400 text-red-400"
              @click="editMode = true"
              v-if="isEditable(selectedWeek) && editMode == false"
            >
              Woche bearbeiten<span v-if="isEditable(selectedWeek) == 'admin'">
                (nur Admin)</span
              >
            </button>
            <button
              class="w-96"
              @click="saveWeek(week)"
              v-else-if="isEditable(selectedWeek) && editMode"
            >
              Woche speichern
            </button>
            <span v-else class="text-red-500 font-bold"
              >Auswahl kann nicht mehr geändert werden.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Mealplanning",
  data() {
    return {
      user: false,
      editMode: false,
      currentContract: {},
      freemeals: [],
      usedmeals: [],
      imported: {
        meals: [],
        desserts: [],
      },
      currentYear: false,
      currentWeek: false,
      project: "",
      contracts: [],
      editedDesserts: false,
      editableDesserts: "",
      editableMeal: "",
      editableMealType: "Vegetarisch",
      editableVariation: "",
      editedMeal: false,
      selectedMealPlan: {},
      selectedFile: null,
      selectedWeek: 0,
      availableWeeks: {},
    };
  },

  methods: {
    chooseDessert(day, dessert) {
      if (!this.editMode) return;
      if (this.selectedMealPlan[day].choices.dessert == dessert) {
        this.selectedMealPlan[day].choices.dessert = "";
      } else {
        this.selectedMealPlan[day].choices.dessert = dessert;
      }
    },
    getUser() {
      API.checkUser((r, status) => {
        if (status === 200) {
          this.user = r;
        }
      });
    },
    chooseMeal(day, meal, index) {
      if (!this.editMode) return;
      if (this.selectedMealPlan[day].choices.main === meal.main || this.selectedMealPlan[day].choices.mainIndex == index) {
        return;
      }
      this.selectedMealPlan[day].choices.main = meal.main;
      this.selectedMealPlan[day].choices.mainIndex = index;
      if (this.selectedMealPlan[day].defaults.main == meal.main || this.selectedMealPlan[day].defaults.mainIndex == index) {
        this.selectedMealPlan[day].choices.variation = this.selectedMealPlan[day].defaults.variation;
        if(this.selectedMealPlan[day].defaults.variationIndex){
          this.selectedMealPlan[day].choices.variationIndex = this.selectedMealPlan[day].defaults.variationIndex;
        }
      } else {
        if (meal.variations.length > 0) {
          this.selectedMealPlan[day].choices.variation = meal.variations[0];
          this.selectedMealPlan[day].choices.variationIndex = 0;
        } else {
          this.selectedMealPlan[day].choices.variation = "";
        }
      }
    },
    chooseVariation(day, variation, index) {
      if (!this.editMode) return;
      this.selectedMealPlan[day].choices.variation = variation;
      this.selectedMealPlan[day].choices.variationIndex = index;
    },
    selectContract(contract) {
      this.currentContract = contract;
      let vm = this;
      this.selectedMealPlan = {};
      this.availableWeeks = {};
      for (let i = 0; i < 7; i++) {
        setTimeout(() => {
          vm.addMealplan(vm.currentWeek + i);
        }, 200 * i);
      }
    },
    setWeek(week) {
      this.selectedWeek = week;
      this.selectedMealPlan = this.availableWeeks[week];
      this.editMode = false;
      console.log(this.selectedMealPlan);
    },
    saveWeek() {
      this.editMode = false;
      let vm = this;
      let choices = {};
      for (let day in this.selectedMealPlan) {
        choices[day] = this.selectedMealPlan[day].choices;
      }
      API.saveMealPlanForWeek(
        this.currentContract.id,
        this.selectedWeek < this.currentWeek
          ? this.currentYear + 1
          : this.currentYear,
        this.selectedWeek,
        choices,
        (r, status) => {
          if (status === 200) {
            vm.$emit("toast", "Woche gespeichert!");
          }
        }
      );
    },
    getWeekDates(weekNumber) {
      const year =
        weekNumber < this.currentWeek ? this.currentYear + 1 : this.currentYear;
      const jan1 = new Date(year, 0, 1);
      const firstDayOfWeek = (jan1.getDay() + 6) % 7; // Adjust so Monday = 0
      const daysOffset = (weekNumber - 1) * 7 - firstDayOfWeek;
      const startDate = new Date(jan1);
      startDate.setDate(jan1.getDate() + daysOffset);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 4); // Assuming work weeks of 5 days

      const startDateStr = startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });
      const endDateStr = endDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });

      return `${startDateStr} - ${endDateStr}`;
    },
    getWeekDateForWeekday(weekNumber, weekday) {
      let year =
        weekNumber < this.currentWeek ? this.currentYear + 1 : this.currentYear;
      let startDate = new Date(
        year,
        0,
        (weekNumber - 1) * 7 - (new Date(year, 0, 0).getDay() - 1)
      );
      let offset = [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
      ].indexOf(weekday);
      startDate.setDate(startDate.getDate() + offset);
      return startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });
    },
    isEditable(weeknr) {
      let normalResult = true; // normalerweise kann man die Woche bearbeiten
      if (weeknr == this.currentWeek)
        normalResult = false; // die aktuelle Woche kann man nicht bearbeiten
      else if (weeknr - this.currentWeek == 1) {
        let date = new Date();
        if (this.currentContract.project.includes("Schaurte")) { // bei schauerte darf man noch bis mittwoch bestellen
          if (
            date.getDay() > 3 ||
            (date.getDay() == 3 && date.getHours() >= 10)
          ) {
            normalResult = false;
          }
        } else if (date.getDay() != 1 || date.getHours() >= 10) { // bei allen anderen nur bis Montag 10:00
          normalResult = false;
        }
      }

      if (!normalResult && this.user && this.user.impersonated) return "admin";
      return normalResult;
    },
    getCurrentWeekOfYear() {
      let date = new Date();
      let dayOfWeek = date.getDay();
      let daysToAdd = dayOfWeek === 0 ? -3 : 4 - dayOfWeek; // days to add for thursday
      let thursdayOfWeek = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + daysToAdd
      );
      console.log(thursdayOfWeek);
      let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      daysToAdd = firstDayOfYear.getDay() === 0 ? -3 : 4 - firstDayOfYear.getDay();
      let firstThursdayOfYear=new Date(
        firstDayOfYear.getFullYear(),
        0,
        1 + daysToAdd
      );
      let days =
        Math.floor((thursdayOfWeek - firstThursdayOfYear) / (24 * 60 * 60 * 1000)) +
        1;
      return Math.floor(days / 7) + 1;
    },
    addMealplan(weekInQuestion) {
      if (!this.currentContract.id) return;
      let vm = this;
      let wiq = weekInQuestion;
      let yiq = this.currentYear;
      if (wiq > this.getMaxISOWeek(yiq)) {
        wiq = wiq - this.getMaxISOWeek(yiq);
        yiq = yiq + 1;
      }
      API.getMealPlanForWeek(this.currentContract.id, yiq, wiq, (r, status) => {
        if (status === 200) {
          let anyMeal = false;
          Object.keys(r).forEach((day) => {
            if (r[day].meals[0].main != "") {
              anyMeal = true;
            }
            if (Object.keys(r[day].choices) == 0) {
              r[day].choices = JSON.parse(JSON.stringify(r[day].defaults));
            }
           // if()f
          });

          if (anyMeal) {
            let first = Object.keys(vm.availableWeeks).length == 0;
            vm.availableWeeks[wiq] = r;
            if (first) {
              vm.setWeek(wiq);
            }
          }
        }
      });
    },
    getContracts() {
      let vm = this;
      API.getEatingContracts((r, status) => {
        if (status === 200) {
          vm.contracts = r;
          if (vm.contracts.length > 0) {
            vm.selectContract(vm.contracts[0]);
          }
        }
      });
    },
    getMaxISOWeek(year) {
      const lastDayOfYear = new Date(year, 11, 31); // December 31st
      const dayOfWeek = lastDayOfYear.getDay() || 7; // Ensure Sunday is 7
      const lastISOWeek = Math.ceil(
        (lastDayOfYear.getDate() - dayOfWeek + 10) / 7
      );
      return lastISOWeek === 53 ? 53 : 52; // Adjust if the year ends with week 53
    },
  },
  created() {
    this.getUser();
    this.currentYear = new Date().getFullYear();
    this.currentWeek = this.getCurrentWeekOfYear();
    this.getContracts();
    window.vm = this;
  },
};
</script>