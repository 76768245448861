<template>
  <div class="w-full h-full flex flex-row">
    <div
      v-if="toast"
      class="rounded-lg text-white font-bold px-6 py-3 w-56 fixed bottom-5 right-5 z-50 transition-transform transform translate-y-0 whitespace-pre"
      :class="
        toastStatus == 'success'
          ? 'bg-green-600'
          : toastStatus == 'warning'
          ? 'bg-orange-300'
          : 'bg-red-500'
      "
    >
      {{ toast }}
    </div>
    <Navigation :title="route" ref="nav" :user="user" />
    <div class="flex flex-grow flex-col relative w-full">
      <Header :title="route" @toggleMenu="toggleMenu" :user="user" />
      <div id="content" class="w-full h-full overflow-y-auto">
        <div class="w-full h-full">
          <Cards v-if="route === 'Mensakarten'" :cards="cards" />
          <Mealplanning
            v-else-if="route === 'Essensplanung'"
            @toast="showToast"
          />
          <Pickup
            v-else-if="route === 'Abholung'"
            @toast="showToast"
            :contracts="pickupContracts"
          />
          <MyData v-else-if="route === 'Meine Daten'" @toast="showToast" />
          <MyServices
            v-else-if="route === 'Meine Leistungen'"
            @toast="showToast"
          />
          <BookService
            v-else-if="route === 'Leistung buchen'"
            @toast="showToast"
          />
          <div v-else-if="loaded" class="">
            <div class="p-4">
              <div class="font-bold w-full bg-white rounded-lg p-4">
                <span class="text-2xl font-bold"
                  >Hallo,
                  <span v-if="user.displayName && user.displayName != ''">
                    {{ user.displayName }}.</span
                  ></span
                ><br /><br />
                <span
                  >Willkommen beim Online-Service der ev-angel-isch gGmbh. Über
                  dieses Portal haben Sie die Möglichkeit, unsere Leistungen zu
                  buchen und Einstellungen vorzunehmen.</span
                >
              </div>
              <Announcements
                v-if="announcements.length > 0"
                :announcements="announcements"
              />
            </div>
            <Cards v-if="cards.length > 0" :cards="cards" />
          </div>
          <div v-else class="p-4">
            <span class="font-bold w-full bg-white rounded-lg p-4 inline-block"
              >Lädt...</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Cards from "@/components/Cards.vue";
import Header from "@/components/Header.vue";
import Navigation from "@/components/Navigation.vue";
import Mealplanning from "@/components/Mealplanning.vue";
import Pickup from "@/components/Pickup.vue";
import MyData from "@/components/MyData.vue";
import MyServices from "@/components/MyServices.vue";
import BookService from "@/components/BookService.vue";
import Announcements from "@/components/Announcements.vue";
import API from "@/API";
export default {
  name: "HomeView",
  components: {
    Navigation,
    Header,
    Cards,
    Mealplanning,
    Pickup,
    MyData,
    BookService,
    MyServices,
    Announcements,
  },
  data() {
    return {
      toast: "",
      route: this.$router.currentRoute.value.name,
      cards: [],
      pickupContracts: [],
      eatingContracts: [],
      showMealPlanning: false,
      loaded: false,
      user: {},
      announcements: [],
    };
  },
  methods: {
    isMaintenance(cb) {
      let vm = this;
      API.isMaintenance((r, status) => {
        if (status === 200) {
          if (r.maintenance) {
            vm.$router.push({ name: "Maintenance" });
          } else {
            cb();
          }
        }else{
          vm.$router.push({ name: "Offline" });
        }
      });
      
    },
    getCards() {
      API.getCards((r, status) => {
        if (status === 200) {
          this.cards = r;
        }
        this.loaded = true;
      });
    },
    getEatingContracts() {
      let vm = this;
      API.getEatingContracts((r, status) => {
        if (status === 200) {
          if (r.length > 0) {
            vm.showMealPlanning = true;
          }
        }
        this.getPickupContracts();
      });
    },
    getPickupContracts() {
      let vm = this;
      API.getPickupContracts((r, status) => {
        if (status === 200) {
          vm.pickupContracts = r;
        }
      });
    },
    getAnnouncements() {
      API.getAnnouncements((r, status) => {
        if (status === 200) {
          this.announcements = r;
        }
      });
    },
    getUser() {
      let vm = this;
      API.checkUser((r, status) => {
        if (status === 200) {
          vm.user = r;
        } else if (status === 401) {
          window.location = "/login";
        }
      });
    },
    showToast(msg, status = "success") {
      this.toast = msg;
      this.toastStatus = status;
      if (this.toastTimer) {
        clearTimeout(this.toastTimer);
      }
      this.toastTimer = setTimeout(() => {
        this.toast = "";
      }, 10000);
    },
    toggleMenu() {
      this.$refs.nav.toggle(true);
    },
  },
  created() {
    this.isMaintenance(() => {
      setTimeout(() => {
        this.getCards();
        this.getUser();
        this.getAnnouncements();
      }, 500);
    });
  },
};
</script>
