<template>
  <div
    class="relative w-full h-full"
    :class="
      currentProject && currentProject.id ? 'overflow-hidden' : 'overflow-auto'
    "
    ref="scroller"
  >
    <div
      v-if="toast"
      @click="toast = false"
      class="rounded-lg text-white font-bold px-6 py-3 max-w-96 fixed bottom-5 right-5 z-50 transition-transform transform translate-y-0"
      :class="toastStatus == 'success' ? 'bg-green-600' : toastStatus == 'warning' ? 'bg-orange-300' : 'bg-red-500'"
    >
      {{ toast }}
    </div>
    <BookingPopup
      v-if="currentProject.id"
      :currentProject="currentProject"
      @toast="showToast"
      @close="currentProject = {}; window.history.pushState(null, null, '/book')"
    />
    <div class="w-full text-center" v-if="customerdata.ProfilKomplett != undefined && !customerdata.ProfilKomplett">
      <span class="bg-red-400 text-lg mt-4 !max-w-96 inline-block px-2 py-1 rounded-lg"
        ><b
          >Weitere Leistungen können erst gebucht werden, wenn ihr Profil vollständig ausgefüllt wurde. Dies können Sie unter <a href='/mydata' class="underline">"Meine Daten"</a> tun.</b
        ><br />Wenden Sie sich für Hilfe an den Support.</span
      >
    </div>
    <div v-else class="p-1 pt-3 md:p-4">
      <div
        v-for="project in projects"
        :key="project"
        class="bg-white rounded-lg p-4 mb-4 mr-4 border-primary border inline-block align-top cursor-pointer w-96"
      >
        <span class="font-bold text-lg h-14 inline-block">{{ project.Titel }}</span>

        <div class="grid md:grid-cols-2">
          <div>
            <span><b>Start: </b>{{ project.StartDatum }}</span
            ><br />
            <span v-if="project.EndDatum"
              ><b>Ende:</b> {{ project.EndDatum }}</span
            >
            <span v-else><b>Ende:</b> Offen</span>
          </div>
          <div class="text-right pt-4">
            <button
              class="w-32"
              @click="
                scrollToTop(() => {
                  selectProject(project);
                })
              "
            >
              <span v-if="project.NurVormerkungen">Vormerken</span>
              <span v-else-if="project.WartelisteAktiv">Warteliste</span>
              <span v-else>Jetzt buchen</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
import BookingPopup from "@/components/BookingPopup.vue";
export default {
  name: "Mealplanning",
  data() {
    return {
      toast: "",
      toastStatus: false,
      customerdata: {},
      projects: [],
      currentProject: {},
      newContract: {},
      toastTimer: null,
      window: window,
    };
  },
  components: {
    BookingPopup,
  },
  methods: {
    scrollToTop(runAfter) {
      this.$refs.scroller.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(runAfter, 500);
    },
    getCustomerData() {
      API.getCustomerData((r, status) => {
        if (status === 200) {
          this.customerdata = r;
        }
      });
    },
    getBookableProjects() {
      API.getBookableProjects((r, status) => {
        if (status === 200) {
          this.projects = r;
          if(this.$route.query.id){
            this.selectProject(this.projects.find(p => p.id == this.$route.query.id));
          }
        }
      });
    },
    selectProject(project) {
      this.currentProject = project;
      window.history.pushState(null, null, '/book?id=' + project.id);
    },
    showToast(msg, status = "success") {
      this.toast = msg;
      this.toastStatus = status;
      if (this.toastTimer) {
        clearTimeout(this.toastTimer);
      }
      this.toastTimer = setTimeout(() => {
        this.toast = "";
      }, 10000);
    },
  },
  created() {
    this.getCustomerData();
    this.getBookableProjects();
  },
};
</script>