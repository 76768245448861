<template>
  <div class="w-full h-full absolute bg-black/50 text-center md:py-4">
    <div
      v-if="editableProject && editableProject.id"
      ref="popup"
      class="bg-white md:rounded-lg inline-block md:w-5/6 relative p-4 text-left max-h-full overflow-auto"
      :class="{ 'overflow-hidden': showCancelPopup }"
    >
      <div
        v-if="showCancelPopup"
        class="absolute m-auto top-0 left-0 right-0 bottom-0 bg-black/50 text-center z-10"
      >
        <div class="w-full inline-block md:mt-10 bg-white rounded-lg p-4">
          <span class="font-bold text-2xl">Kündigen</span><br />
          <div class="text-left mt-4">
            <span
              >Wollen Sie den Vertrag <b>{{ editableContract.Titel }}</b> für
              <b>{{ editableContract.Vorname }}</b> wirklich kündigen? Das
              nächste Kündigungsdatum ist der
              <b>{{ editableContract.cancelDate }}</b
              >.<br />
              <div v-if="editableContract.cancelCost">
                <span v-if="editableContract.cancelCost == '0 €'"
                  >Für die Kündigung fallen zum aktuellen Zeitpunkt keine Kosten
                  an.</span
                ><span v-else
                  >Für die Kündigung fallen dennoch Kosten in Höhe von
                  <b>{{ editableContract.cancelCost }}</b> an.</span
                >
              </div></span
            >
            <div class="text-center">
              <button @click="showCancelPopup = false" class="mx-2 mt-2">
                Abbrechen
              </button>
              <button @click="cancelContract()" class="mx-2 red">
                Wirklich kündigen
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showRevokePopup"
        class="absolute m-auto top-0 left-0 right-0 bottom-0 bg-black/50 text-center z-10"
      >
        <div class="w-full inline-block md:mt-10 bg-white rounded-lg p-4">
          <span class="font-bold text-2xl">Vertrag widerrufen</span><br />
          <div class="text-left mt-4">
            <span
              >Wollen Sie den Vertrag <b>{{ editableContract.Titel }}</b> für
              <b>{{ editableContract.Vorname }}</b> wirklich widerrufen? Für das
              Widerrufen des Vertrags fallen keine Kosten an.</span
            >
            <div class="text-center">
              <button @click="showRevokePopup = false" class="mx-2 mt-2">
                Abbrechen
              </button>
              <button @click="cancelContract()" class="mx-2 red">
                Wirklich widerrufen
              </button>
            </div>
          </div>
        </div>
      </div>
      <span class="text-2xl font-bold mr-2">{{ editableProject.Titel }}</span>
      <span
        v-if="editableContract.Status"
        :class="{
          'text-red-500 px-2 py-1 border-2 border-red-500 rounded': [
            'Gekündigt',
            'Widerrufen',
          ].includes(editableContract.Status),
          'text-green-500 px-2 py-1 border-2 border-green-500 rounded': [
            'Bestätigt',
          ].includes(editableContract.Status),
          'text-yellow-500 px-2 py-1 border-2 border-yellow-500 rounded': [
            'Eingegangen',
            'Warteliste',
            'Kündigung eingegangen',
          ],
        }"
        >{{ editableContract.Status }}</span
      >
      <font-awesome-icon
        icon="xmark"
        class="text-3xl text-primary cursor-pointer float-right m-3"
        @click="$emit('close')"
      />
      <pre class="whitespace-break-spaces font-sans text-left w-full py-4">{{
        editableProject.Beschreibung
      }}</pre>
      <div
        v-if="editableContract.Dateien && editableContract.Dateien.length > 0"
        class="mb-8"
      >
        <span
          class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
          >Dateien</span
        >
        <div v-for="file in editableContract.Dateien" :key="file">
          <a
            :href="frontend + '/files/api/project/' + file.id"
            target="_blank"
            class="border-b border-primary font-bold"
            >{{ file.name }}</a
          >
        </div>
      </div>
      <div class="grid md:grid-cols-2 border-t border-black pt-2">
        <span><b>Start: </b>{{ editableProject.StartDatum }}</span>
        <span class="underline text-xl"
          ><b>Kosten: {{ editableContract.Kosten }}</b></span
        >
        <span
          ><b>Ende: </b
          >{{
            editableProject.EndDatum == null
              ? "Offen"
              : editableProject.EndDatum
          }}</span
        >
        <span v-if="editableContract.SEPAMandatsreferenz"
          ><b>SEPA-Mandatsreferenz: </b
          >{{ editableContract.SEPAMandatsreferenz }}</span
        >
      </div>
      <span
        class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
        >Allgemeine Angaben</span
      >
      <div class="grid md:grid-cols-2">
        <div class="pr-2">
          <label>Vorname Kind:</label>
          <input v-model="editableContract.Vorname" placeholder="Vorname" />
        </div>
        <div class="pr-2">
          <label>Nachname Kind:</label>
          <input v-model="editableContract.Nachname" placeholder="Nachname" />
        </div>
        <div class="pr-2">
          <label>Geschlecht:</label>
          <select v-model="editableContract.Geschlecht">
            <option value="">Bitte auswählen</option>
            <option value="Männlich">Männlich</option>
            <option value="Weiblich">Weiblich</option>
            <option value="Divers">Divers</option>
          </select>
        </div>
        <div class="pr-2">
          <label>Geburtsdatum:</label>
          <input
            type="date"
            v-model="editableContract.Geburtsdatum"
            placeholder="Geburtsdatum"
          />
        </div>
        <div v-if="editableProject.GeburtsortAbfragen" class="pr-2">
          <label>Geburtsort:</label>
          <input
            v-model="editableContract.Geburtsort"
            placeholder="Geburtsort"
          />
        </div>
        <div v-if="editableProject.SchulischeAngaben" class="pr-2">
          <label>Klasse:</label>
          <input v-model="editableContract.Klasse" placeholder="Klasse" />
        </div>
        <div v-if="editableProject.SchulischeAngaben" class="pr-2">
          <label>Schuljahr:</label>
          <input v-model="editableContract.Schuljahr" placeholder="Schuljahr" />
        </div>
        <div class="pr-2">
          <input
            id="Geschwisterkind"
            type="checkbox"
            v-model="editableContract.Geschwisterkind"
            placeholder="Geschwisterkind"
          />
          <label for="Geschwisterkind">Geschwisterkind</label>
        </div>
        <div v-if="editableProject.BehinderungAbfragen" class="pr-2">
          <input
            id="Behinderung"
            type="checkbox"
            v-model="editableContract.Behinderung"
            placeholder="Behinderung"
          />
          <label for="Behinderung">Behinderung</label>
        </div>
        <div v-if="editableProject.AbholungNotwendigAbfragen" class="pr-2">
          <input
            id="AbholungNotwendig"
            type="checkbox"
            v-model="editableContract.AbholungNotwendig"
            placeholder="AbholungNotwendig"
          />
          <label for="AbholungNotwendig"
            >Kind muss persönlich abgeholt werden</label
          >
        </div>
        <div
          v-if="
            editableProject.Betreuungsendeoptionen &&
            editableProject.Betreuungsendeoptionen.split(',').length > 1
          "
          class="pr-2"
        >
          <label>Betreuungsende</label>
          <select v-model="editableContract.Betreuungsende">
            <option
              :value="option"
              v-for="option in editableProject.Betreuungsendeoptionen.split(
                ','
              )"
              :key="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="pr-2">
          <label>Bemerkung:</label>
          <input v-model="editableContract.Bemerkung" placeholder="Bemerkung" />
        </div>
      </div>

      <div v-if="editableProject.Preisauswahl && !editableProject.Tagesauswahl">
        <span
          class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
          >Kosten</span
        >
        <div v-if="editableProject.Preisauswahl1">
          <input
            id="Preisauswahl1"
            name="Kosten"
            v-model="editableContract.Kosten"
            type="radio"
            :value="editableProject.Preisauswahl1"
          /><label for="Preisauswahl1">{{
            editableProject.Preisauswahl1
          }}</label>
        </div>
        <div v-if="editableProject.Preisauswahl2">
          <input
            id="Preisauswahl2"
            name="Kosten"
            v-model="editableContract.Kosten"
            type="radio"
            :value="editableProject.Preisauswahl2"
          /><label for="Preisauswahl2">{{
            editableProject.Preisauswahl2
          }}</label>
        </div>
        <div v-if="editableProject.Preisauswahl3">
          <input
            id="Preisauswahl3"
            name="Kosten"
            v-model="editableContract.Kosten"
            type="radio"
            :value="editableProject.Preisauswahl3"
          /><label for="Preisauswahl3">{{
            editableProject.Preisauswahl3
          }}</label>
        </div>
        <div v-if="editableProject.Preisauswahl4">
          <input
            id="Preisauswahl4"
            name="Kosten"
            v-model="editableContract.Kosten"
            type="radio"
            :value="editableProject.Preisauswahl4"
          /><label for="Preisauswahl4">{{
            editableProject.Preisauswahl4
          }}</label>
        </div>
        <div v-if="editableProject.Preisauswahl5">
          <input
            id="Preisauswahl5"
            name="Kosten"
            v-model="editableContract.Kosten"
            type="radio"
            :value="editableProject.Preisauswahl5"
          /><label for="Preisauswahl5">{{
            editableProject.Preisauswahl5
          }}</label>
        </div>
      </div>
      <div v-if="editableProject.Tagesauswahl">
        <span
          class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
          >Tagesauswahl</span
        >
        <div class="grid md:grid-cols-2">
          <div class="pr-2">
            <input
              id="Montag"
              type="checkbox"
              @change="calculateCost"
              v-model="editableContract.Montag"
              placeholder="Montag"
            />
            <label for="Montag">Montag</label>
          </div>
          <div class="pr-2">
            <input
              id="Dienstag"
              type="checkbox"
              @change="calculateCost"
              v-model="editableContract.Dienstag"
              placeholder="Dienstag"
            />
            <label for="Dienstag">Dienstag</label>
          </div>
          <div class="pr-2">
            <input
              id="Mittwoch"
              type="checkbox"
              @change="calculateCost"
              v-model="editableContract.Mittwoch"
              placeholder="Mittwoch"
            />
            <label for="Mittwoch">Mittwoch</label>
          </div>
          <div class="pr-2">
            <input
              id="Donnerstag"
              type="checkbox"
              @change="calculateCost"
              v-model="editableContract.Donnerstag"
              placeholder="Donnerstag"
            />
            <label for="Donnerstag">Donnerstag</label>
          </div>
          <div class="pr-2" v-if="!editableProject.FreitagAusblenden">
            <input
              id="Freitag"
              type="checkbox"
              @change="calculateCost"
              v-model="editableContract.Freitag"
              placeholder="Freitag"
            />
            <label for="Freitag">Freitag</label>
          </div>
        </div>
      </div>
      <div v-if="editableProject.Essensangaben">
        <span
          class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
          >Essensangaben</span
        >
        <div class="grid md:grid-cols-2">
          <div class="pr-2">
            <input
              id="Vegetarisch"
              type="checkbox"
              v-model="editableContract.Vegetarisch"
              placeholder="Vegetarisch"
            />
            <label for="Vegetarisch">Vegetarisch</label>
          </div>
          <div class="pr-2">
            <input
              id="Schweinefleischfrei"
              type="checkbox"
              v-model="editableContract.Schweinefleischfrei"
              placeholder="Schweinefleischfrei"
            />
            <label for="Schweinefleischfrei">Schweinefleischfrei</label>
          </div>
          <div class="pr-2">
            <input
              id="Laktosefrei"
              type="checkbox"
              v-model="editableContract.Laktosefrei"
              placeholder="Laktosefrei"
            />
            <label for="Laktosefrei">Laktosefrei</label>
          </div>
          <div class="pr-2">
            <input
              id="Zöliakie"
              type="checkbox"
              v-model="editableContract.Zoeliakie"
              placeholder="Zöliakie"
            />
            <label for="Zöliakie">Zöliakie</label>
          </div>
          <div class="pr-2">
            <input
              id="Nussallergie"
              type="checkbox"
              v-model="editableContract.Nussallergie"
              placeholder="Nussallergie"
            />
            <label for="Nussallergie">Nussallergie</label>
          </div>
        </div>
        <div
          v-if="
            editableContract.Zoeliakie ||
            editableContract.Nussallergie ||
            editableContract.Laktosefrei
          "
        >
          <label>Nachweisdokument</label>
          <input type="file" @change="handleFileChange($event, 'allergy')" />
        </div>
      </div>
      <div v-if="editableProject.BUTAbfrage">
        <span
          class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
          >Förderung</span
        >
        <div class="">
          <div class="pr-2">
            <input
              id="BuT-berechtigt"
              type="checkbox"
              v-model="editableContract.BUTBerechtigt"
              placeholder="BuT-berechtigt"
            />
            <label for="BuT-berechtigt">BuT-berechtigt</label>
          </div>
          <div
            v-if="editableContract.BUTBerechtigt && editableProject.BUTAuswahl"
            class="pr-2"
          >
            <span
              >Sie haben Anspruch aus Leistungen für Bildung und Teilhabe. Bitte
              wählen sie ihre Leistungsart aus. Im Anschluss können sie die
              AKTUELLEN Bescheide hochladen (dies ist auch innerhalb von 14
              Tagen nach Vertragsabschluss im Anmeldebereich möglich). Sie
              können die Unterlagen auch an : ev-angel-isch gGmbH, Venloer Str.
              1055, 50289 Köln binnen 14 Tage nach Vertragsabschluss per Post
              schicken. Sollte unsere Prüfung ergeben, dass sie keinen Anspruch
              auf Leistungen aus Bildung und Teilhabe haben, fallen die
              angezeigten Kosten pro Monat an. Sollten Sie hierzu Fragen haben
              oder Unterstützung benötigen rufen sie uns unter 0221-16959590 an.
              Gerne helfen ihnen auch der Schulsozialarbeiter und unsere
              Ansprechpartner vor Ort).</span
            ><br />
            <label>Leistungsart</label><br />
            <input
              id="Leistungsbescheid Jobcenter"
              name="BUTArt"
              type="radio"
              v-model="editableContract.BUTArt"
              value="Leistungsbescheid Jobcenter"
            /><label for="Leistungsbescheid Jobcenter"
              >Leistungsbescheid Jobcenter</label
            ><br />
            <input
              id="Wohngeldbescheid"
              name="BUTArt"
              v-model="editableContract.BUTArt"
              type="radio"
              value="Wohngeldbescheid"
            /><label for="Wohngeldbescheid">Wohngeldbescheid</label><br />
            <input
              id="Kinderzuschlag"
              name="BUTArt"
              v-model="editableContract.BUTArt"
              type="radio"
              value="Kinderzuschlag"
            /><label for="Kinderzuschlag">Kinderzuschlag</label><br />
            <input
              id="Leistungsbescheid nach dem Asylbewerbergesetz"
              name="BUTArt"
              v-model="editableContract.BUTArt"
              type="radio"
              value="Leistungsbescheid nach dem Asylbewerbergesetz"
            /><label for="Leistungsbescheid nach dem Asylbewerbergesetz"
              >Leistungsbescheid nach dem Asylbewerbergesetz</label
            ><br />
            <label>Nachweisdokument</label>
            <input type="file" @change="handleFileChange($event, 'but')" />
          </div>
          <div v-else-if="editableContract.BUT" class="pr-2">
            <span
              >Sie haben Anspruch aus Leistungen für Bildung und Teilhabe, weil
              sie einen Leistungsbescheid vom Jobcenter haben, Wohngeld beziehen
              oder einen Kinderzuschlag bekommen. Nach Vertragabschluss bekommen
              Sie alle auszufüllenden Unterlagen und Infos per Mail zugesandt.
              Sollte unsere Prüfung ergeben, dass sie keinen Anspruch auf
              Leistungen aus Bildung und Teilhabe haben, fallen angezeigten
              Kosten pro Monat an.</span
            >
          </div>
        </div>
      </div>
      <div v-if="editableProject.UploadDokument1">
        <span
          class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
          >Dateiupload</span
        >
        <div class="">
          <div>
            <label>Dokumententyp</label><br />
            <template v-for="docId in ['1', '2', '3', '4', '5']" :key="docId">
              <input
                v-if="editableProject['UploadDokument' + docId]"
                :id="'UploadDoc' + docId"
                name="BUTArt"
                type="radio"
                v-model="editableContract.uploadDocumentType"
                :value="editableProject['UploadDokument' + docId]"
              /><label :for="'UploadDoc' + docId" class="block">{{
                editableProject["UploadDokument" + docId]
              }}</label></template
            >
            <input v-if="editableContract.uploadDocumentType" type="file" @change="handleFileChange($event, 'upload')" />
          </div>
        </div>
      </div>
      <span
        class="font-bold text-xl text-left border-b-primary border-b-2 w-full my-4 inline-block"
        >Rechtliches</span
      >
      <div class="">
        <div class="pr-2">
          <input
            id="AGB"
            type="checkbox"
            v-model="editableContract.AGB"
            placeholder="AGB"
          />
          <label for="AGB">*Hiermit stimme ich den AGBs zu.</label>
        </div>
        <div class="pr-2">
          <input
            id="Datenschutz"
            type="checkbox"
            v-model="editableContract.Datenschutz"
            placeholder="Datenschutz"
          />
          <label for="Datenschutz"
            >*Mit der Speicherung meiner Daten bin ich einverstanden.
            ev-angel-isch gemeinnützige GmbH verpflichtet sich, sie nicht an
            Dritte weiter zu geben und sie ausschließlich zur Verwaltung der
            Teilnahme an diesem Projekt zu nutzen.</label
          >
        </div>
        <div class="pr-2">
          <input
            id="SEPAMandat"
            type="checkbox"
            v-model="editableContract.SEPAMandat"
            placeholder="SEPAMandat"
          />
          <label for="SEPAMandat"
            >*Ich ermächtige / Wir ermächtigen den Zahlungsempfänger
            ev-angel-isch gGmbH Zahlungen von meinem / unserem Konto mittels
            Lastschrift einzuziehen. Zugleich weise ich mein / weisen wir unser
            Kreditinstitut an, die vom Zahlungsempfänger ev-angel-isch gGmbH auf
            mein / unser Konto gezogenen Lastschriften einzulösen.</label
          >
        </div>
        <div v-if="editableProject.ZustimmungFoto" class="pr-2">
          <input
            id="Fotoerklärung"
            type="checkbox"
            v-model="editableContract.Fotoerklaerung"
            placeholder="Fotoerklärung"
          />
          <label for="Fotoerklärung"
            >Das Team beabsichtigt Personenabbildungen: - Zur Verwendung in
            regionalen Presse- und Medienerzeugnissen im Rahmen des Projekts -
            Zur Verwendung im Internet auf der Homepage www.ev-angel-isch.de, -
            Zur Verwendung in Sponsorenmappen in den künftigen Jahren.
            Personenabbildungen in diesem Sinne sind Fotos, Grafiken,
            Zeichnungen oder Videoveröffentlichungen auf denen Personen
            individuell erkennbar sind. Hiermit willige/n ich/wir in die
            Anfertigung von Personenabbildungen ein. Darüber hinaus willige/n
            ich/wir in die oben genannte Verwendung der Personenabbildungen ohne
            weitere Genehmigung ein. Die Einräumung der Rechte erfolgt ohne
            Vergütung und umfasst auch das Recht zur Bearbeitung, soweit diese
            nicht entstellend werden. Im Falle des Widerrufs dürfen
            Einzelabbildungen zukünftig nicht mehr verwendet werden. Soweit die
            Einwilligung nicht widerrufen wird, gilt sie zeitlich unbeschränkt.
            Die Einwilligung ist freiwillig; aus der Verweigerung der
            Einwilligung oder ihrem Widerruf entstehen keine Nachteile.</label
          >
        </div>
      </div>
      <div class="w-full text-center">
        <div class="text-left" v-if="bookhintVisible">
        <span class="text-xl text-red-600 font-bold whitespace-pre ">{{ editableProject.Buchungshinweis }}</span></div>
        <button
          v-if="!editableContract.id && !loading"
          class="full-red mt-4"
          @click="bookProject()"
        >
          Jetzt kostenpflichtig buchen!
        </button>
        <div v-if="loading" class="p-5 bg-red-500 inline-block relative">
          <div class="lds-dual-ring"></div>
        </div>
        <button
          v-if="editableContract.active"
          @click="changeContractDetails()"
          class="ml-2"
        >
          Angaben per Mail ändern
        </button>
        <button
          v-if="
            editableContract.active &&
            editableProject.KuendigungMoeglich &&
            ['Eingegangen', 'Bestätigt', 'Warteliste', 'Vorgemerkt'].includes(
              editableContract.Status
            )
          "
          class="full-red mt-4"
          @click="checkCancelContract()"
        >
          <span v-if="editableContract.revokable">Vertrag widerrufen</span
          ><span v-else>Vertrag kündigen</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/API";

export default {
  emits: ["toast", "close"],
  props: {
    currentProject: Object,
    currentContract: Object,
    readonly: Boolean,
  },

  data() {
    return {
      editableContract: {},
      editableProject: {},
      showCancelPopup: false,
      showRevokePopup: false,
      askedForPhoto: false,
      loading: false,
      bookhintVisible: false,
      frontend: process.env.VUE_APP_EVLINE_URL,
    };
  },
  methods: {
    changeContractDetails() {
      let cc = "";
      if (this.editableContract.changeContractAddress) {
        cc = "cc=" + this.editableContract.changeContractAddress + "&";
      }
      window.open(
        "mailto:" +
          this.editableContract.contactmail +
          "?" +
          cc +
          "subject=Änderung an Vertrag " +
          this.editableContract.id,
        "_blank"
      );
    },
    cancelContract() {
      API.cancelContract(this.editableContract.id, (r, status) => {
        if (status === 200) {
          if (r.description) {
            this.$emit("toast", r.description, "success");
          } else {
            this.$emit("toast", "Vertrag gekündigt", "success");
          }
          this.$emit("close");
        } else if (r.error) {
          this.$emit("toast", r.error, "error");
        } else {
          this.$emit("toast", "Vertrag konnte nicht gekündigt werden", "error");
        }
        this.showCancelPopup = false;
      });
    },
    checkCancelContract() {
      if (this.editableProject.KuendigungMoeglich) {
        if (this.editableContract.revokable) {
          this.scrollToTop(() => {
            this.showRevokePopup = true;
          });
        } else if (this.editableContract.cancellable) {
          this.scrollToTop(() => {
            this.showCancelPopup = true;
          });
        } else {
          this.$emit(
            "toast",
            "Vertrag kann nicht gekündigt werden. Der letztmögliche Zeitpunkt war der " +
              this.editableContract.cancelDate +
              ".",
            false
          );
        }
      }
    },
    scrollToTop(runAfter) {
      this.$refs.popup.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(runAfter, 1000);
    },
    calculateCost() {
      if (this.editableProject.Preisauswahl) {
        let days = 0;
        ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"].forEach(
          (day) => {
            if (this.editableContract[day]) days++;
          }
        );
        if (days == 0) days++;
        console.log("Preisauswahl" + days);
        this.editableContract.Kosten =
          this.editableProject["Preisauswahl" + days];
      }
    },
    handleFileChange(event, type) {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        this.editableContract[type + "file"] = event.target.result;
        this.editableContract[type + "filename"] = this.file.name;
        if (this.editableContract.id) {
          API.uploadContractDocument(
            this.editableContract.id,
            this.file.name,
            event.target.result,
            this.editableContract.uploadDocumentType,
            (r, status) => {
              if (status === 200) {
                this.$emit("toast", "Dokument hochgeladen", "success");
              }
            }
          );
        }
      };
      reader.readAsDataURL(this.file);
    },
    bookProject() {
      if (
        this.editableProject.ZustimmungFoto &&
        !this.editableContract.Fotoerklaerung &&
        !this.askedForPhoto
      ) {
        this.askedForPhoto = true;
        this.$emit(
          "toast",
          "Sie würden uns sehr helfen, wenn Sie uns die Zustimmung für Personenabbildungen geben würden. Andernfalls speichern Sie einfach erneut.",
          "warning"
        );
        return;
      }
      if(this.editableProject.Buchungshinweis && this.editableProject.Buchungshinweis != "" && !this.bookhintVisible) {
        this.bookhintVisible = true;
        return;
      }
      this.loading = true;
      API.bookProject(
        this.editableProject.id,
        this.editableContract,
        (r, status) => {
          this.loading = false;
          if (status === 200) {
            if (r.description) {
              this.$emit("toast", r.description, "success");
            } else {
              this.$emit("toast", "Buchung erfolgreich", "success");
            }
            this.$emit("close");

          } else if (r.error) {
            this.$emit("toast", r.error, "error");
          }
        }
      );
    },
    initPricing() {
      if (this.editableContract.id) {
        if (
          this.editableProject.Preisauswahl &&
          !this.editableProject.Tagesauswahl
        ) {
          this.editableContract.Kosten = [
            this.editableProject.Preisauswahl1,
            this.editableProject.Preisauswahl2,
            this.editableProject.Preisauswahl3,
            this.editableProject.Preisauswahl4,
            this.editableProject.Preisauswahl5,
          ].filter((x) => x.includes(this.editableContract.Kosten))[0];
        }
      } else {
        if (
          this.editableProject.Preisauswahl &&
          !this.editableProject.Tagesauswahl
        ) {
          this.editableContract.Kosten = this.editableProject.Preisauswahl3;
        } else {
          this.editableContract.Kosten = this.editableProject.Kosten;
        }
        if (this.editableProject.Betreuungsendeoptionen.split(",").length > 1) {
          this.editableContract.Betreuungsende =
            this.editableProject.Betreuungsendeoptionen.split(",")[0];
        }
      }
      if (this.readonly) {
        setTimeout(() => {
          this.$refs.popup
            .querySelectorAll("input, textarea, select")
            .forEach((input) => {
              console.log("Disabling " + input.id);
              if (input.type !== "file" && !input.id.includes("UploadDoc")) {
                input.disabled = true;
              }
            });
        }, 150);
      }
    },
  },
  mounted() {
    if (this.currentContract) {
      this.editableContract = this.currentContract;
    } else {
      this.editableContract = {
        Geschlecht: "",
        Betreuungsende: "",
      };
    }
    if (this.currentProject) {
      this.editableProject = this.currentProject;
      this.initPricing();
    } else if (this.editableContract.Projekt) {
      API.getBookableProject(this.editableContract.Projekt, (r, status) => {
        if (status === 200) {
          this.editableProject = r;
          this.initPricing();
        }
      });
    }
  },
};
</script>
