<template>
  <div
    v-for="announcement in announcements"
    :key="announcement.id"
    class="bg-white p-4 rounded-lg text-left w-full mt-4"
  >
    <div class="md:grid grid-cols-3">
      <div class="text-center flex items-center justify-center pr-5">
        <img
          v-if="announcement.image"
          :src="'data:image/jpeg;base64, ' + announcement.image"
        />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="inline-block w-20"
        >
          <path
            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
          />
        </svg>
      </div>
      <div class="col-span-2">
        <span class="text-2xl font-bold block">{{ announcement.title }}</span>
        <pre
          class="pre-wrap whitespace-break-spaces my-2"
        ><span style="font-family: sans-serif">{{announcement.text}}</span></pre>
        <div v-if="announcement.infolink || announcement.bookinglink">
          <button
            v-if="announcement.infolink"
            class="full mt-4"
            @click="window.location=announcement.infolink"
          >
            Mehr erfahren
          </button>
          <button
            v-if="announcement.bookinglink"
            class="full mt-4"
            @click="window.location=announcement.bookinglink"
          >
            Buchen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: "Announcements",
  props: {
    announcements: Array,
  },
  data() {
    return {
      window: window,
    };
  },
};
</script>
  