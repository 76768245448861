<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center p-4">
      <img src="@/assets/logo.png" class="h-44" />
      <div class="bg-white rounded-lg p-4 mt-4 border-red-500 border-2 md:max-w-[66%]">
          <div class="w-full text-center">
          <font-awesome-icon icon="triangle-exclamation" class="text-2xl" /><span class=" px-2 text-2xl font-bold">Keine Verbindung</span> <font-awesome-icon icon="triangle-exclamation" class="text-2xl" />
      </div><p class="text-lg mt-4">Es kann aktuell keine Verbindung zu unserem System hergestellt werden. Das könnte an Ihrer Internet-Verbindung, oder an technischen Problemen liegen. Prüfen Sie ihre Verbindung oder versuchen Sie es später erneut.</p>
      </div>
    </div>
  </template>
  
  <script>
  import API from "@/API";
  export default {
      data() {
          return {
              maintenance: {},
              interval: null
          }
      },
      methods: {
          getMaintenanceStatus() {
              API.isMaintenance((r, status) => {
                  if (status === 200) {
                      this.maintenance = r;
                      clearInterval(this.interval);
                      if(!r.maintenance) {
                          this.$router.push({ name: "Home" });
                      }
                      else{
                          this.$router.push({ name: "Maintenance" });
                      }
                  }
              })
          }
      },
      mounted() {
          this.getMaintenanceStatus();
          this.interval=setInterval(() => {
              this.getMaintenanceStatus();
          },10000)
      }
  }
  </script>
  
  <style>
  
  </style>