<template>
    <div class="w-full h-20 bg-white z-10 pt-2 pr-4 flex-grow">      
        <span class="font-bold pl-4 pt-2 text-4xl align-middle inline-block bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent h-[1.3em]">{{ title }}</span>
        <span v-if="user" class="font-bold text-xl align-top float-right hidden sm:inline-block">Willkommen,<br>{{ user.user }}</span>
        <font-awesome-icon icon="bars" class="float-right inline-block sm:hidden text-3xl text-primary cursor-pointer mt-3" @click="$emit('toggleMenu')"/>
    </div>
</template>
  
<script>
// @ is an alias to /src
export default {
    name: 'Header',
    props: ['title','user'],

}
</script>
  