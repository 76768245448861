<template>
    <div class="center !top-20 text-center">
        <span class="inline-block text-xl font-bold text-primary mb-4">Login erfolgreich</span>
        <a :href="redirect" class="w-full bg-primary font-bold px-4 py-2">Zurück zur App</a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            redirect: process.env.VUE_APP_FE_URL+"/login?"
        }
    },
    mounted() {
        //add all current query parameters to the redirect var
        for (let key in this.$route.query) {
            this.redirect += "&" + key + "=" + this.$route.query[key];
        }
    }
}
</script>

<style>

</style>