<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center p-4">
      <img src="@/assets/logo.png" class="h-44" />
      <div class="bg-white rounded-lg p-4 mt-4 border-red-500 border-2 md:max-w-[66%]">
        <div class="w-full text-center">
        <font-awesome-icon icon="triangle-exclamation" class="text-2xl" /><span class=" px-2 text-2xl font-bold">Wartungsarbeiten</span> <font-awesome-icon icon="triangle-exclamation" class="text-2xl" />
    </div><p class="text-lg mt-4">Wir führen aktuell Wartungsarbeiten durch. <span v-if="maintenance.until">Die Wartungsarbeiten werden voraussichtlich bis  <b>{{ maintenance.until }}</b> abgeschlossen.</span></p>
    </div>
  </div>
</template>

<script>
import API from "@/API";
export default {
    data() {
        return {
            maintenance: {},
            interval: null
        }
    },
    methods: {
        getMaintenanceStatus() {
            API.isMaintenance((r, status) => {
                if (status === 200) {
                    this.maintenance = r;
                    if(!r.maintenance) {
                        clearInterval(this.interval);
                        this.$router.push({ name: "Home" });
                    }
                }
            })
        }
    },
    mounted() {
        this.getMaintenanceStatus();
        this.interval=setInterval(() => {
            this.getMaintenanceStatus();
        },10000)
    }
}
</script>

<style>

</style>