<template>
  <div class="h-full bg-white z-20 sm:inline-block shrink-0" :class="{ 
    'absolute w-full ': takeAll, 
    'relative': !takeAll, 
    '!w-64 inline-block': !takeAll && expanded, 
    'w-16 hidden': !expanded }">
    <div class="relative h-20 text-center p-2 inline-block w-full">
      <img src="@/assets/logo.png" class="max-w-full max-h-full inline-block" alt="Rescanner logo" />
    </div>

    <div :class="expanded ? 'px-4' : 'text-center'">
      <div v-if="expanded" class="w-full text-right ">
        <font-awesome-icon icon="arrow-left" class="text-3xl text-primary cursor-pointer" @click="expanded = false; takeAll = false" />
      </div>
      <font-awesome-icon v-else icon="bars" class="text-3xl text-primary cursor-pointer" @click="expanded = true" />
      <div class="w-full">
        <a href="/" class="inline-block border-b-4 text-3xl  hover:border-primarylight cursor-pointer pt-3" :class="title === 'Home' ? 'border-primary' : 'border-white'">
          <font-awesome-icon icon="home" class="w-8" :class="title === 'Home' ? 'text-primary' : 'text-black'" />
          <span v-if="expanded" class="ml-2 text-lg font-bold">Home</span>
        </a>
      </div>
      
      <div v-if="user.subaccount == false" class="w-full" >
        <a href="/mydata" class="inline-block border-b-4 text-3xl  hover:border-primarylight cursor-pointer pt-3" :class="title === 'Meine Daten' ? 'border-primary' : 'border-white'">
          <font-awesome-icon icon="address-card" class="w-8" :class="title === 'Meine Daten' ? 'text-primary' : 'text-black'" />
          <span v-if="expanded" class="ml-2 text-lg font-bold">Meine Daten</span>
        </a>
      </div>
      <div v-if="user.subaccount == false && user.anyContract" class="w-full" >
        <a href="/services" class="inline-block border-b-4 text-3xl  hover:border-primarylight cursor-pointer pt-3" :class="title === 'Meine Leistungen' ? 'border-primary' : 'border-white'">
          <font-awesome-icon icon="file-contract" class="w-8" :class="title === 'Meine Leistungen' ? 'text-primary' : 'text-black'" />
          <span v-if="expanded" class="ml-2 text-lg font-bold">Meine Leistungen</span>
        </a>
      </div>
      <div v-if="user.subaccount == false"  class="w-full" >
        <a href="/book" class="inline-block border-b-4 text-3xl  hover:border-primarylight cursor-pointer pt-3" :class="title === 'Leistung buchen' ? 'border-primary' : 'border-white'">
          <font-awesome-icon icon="cart-shopping" class="w-8" :class="title === 'Leistung buchen' ? 'text-primary' : 'text-black'" />
          <span v-if="expanded" class="ml-2 text-lg font-bold">Leistung buchen</span>
        </a>
      </div>
      <div v-if="user.mealplan" class="w-full">
        <a href="/cards" class="inline-block border-b-4 text-3xl  hover:border-primarylight cursor-pointer pt-3" :class="title === 'Mensakarten' ? 'border-primary' : 'border-white'">
          <font-awesome-icon icon="credit-card" class="w-8" :class="title === 'Mensakarten' ? 'text-primary' : 'text-black'" />
          <span v-if="expanded" class="ml-2 text-lg font-bold">Mensakarten</span>
        </a>
      </div>
      <div v-if="user.mealplan" class="w-full">
        <a href="/mealplanning" class="inline-block border-b-4 text-3xl  hover:border-primarylight cursor-pointer pt-3" :class="title === 'Essensplanung' ? 'border-primary' : 'border-white'">
          <font-awesome-icon icon="utensils" class="w-8" :class="title === 'Essensplanung' ? 'text-primary' : 'text-black'" />
          <span v-if="expanded" class="ml-2 text-lg font-bold">Essensplanung</span>
        </a>
      </div>
      <div v-if="user.subaccount == false && user.pickup" class="w-full">
        <a href="/pickup" class="inline-block border-b-4 text-3xl  hover:border-primarylight cursor-pointer pt-3" :class="title === 'Abholung' ? 'border-primary' : 'border-white'">
          <font-awesome-icon icon="person-walking-dashed-line-arrow-right" class="w-8" :class="title === 'Abholung' ? 'text-primary' : 'text-black'" />
          <span v-if="expanded" class="ml-2 text-lg font-bold">Abholung</span>
        </a>
      </div>
    </div>

    <div class="absolute left-0 bottom-0 w-full text-center">
      <a v-if="user.hasRealAccount" :href="frontend+'/changepw'">
        <button v-if="expanded" class="full">Passwort ändern</button>
        <font-awesome-icon v-else class="text-3xl text-red-300 cursor-pointer mb-2" icon="key" />
      </a>
      <div class="mt-2" @click="logout">
        <button v-if="expanded" class="full" >Abmelden</button>
        <font-awesome-icon v-else class="text-3xl text-red-300 cursor-pointer mb-2" icon="right-from-bracket" />
      </div>
      <a v-if="expanded" href="https://www.ev-angel-isch.de/impressum-2/" class="text-sm mx-3 underline cursor-pointer text-gray-500 font-bold">Impressum</a>
      <a v-if="expanded" href="https://www.ev-angel-isch.de/datenschutz/" class="text-sm mx-3 underline cursor-pointer text-gray-500 font-bold">Datenschutz</a>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import API from "@/API"
export default {
  name: 'Header',
  props: ['title', 'user'],
  data() {
    return {
      frontend: process.env.VUE_APP_EVLINE_URL,
      expanded: true,
      takeAll: false
    }
  },
  exposes: ['toggle'],
  methods: {
    toggle(takeAll = false) {
      console.log("toggling")
      this.takeAll = takeAll
      this.expanded = !this.expanded
    },
    logout() {
      API.logout((r, status) => {
        if (status === 200) {
          if(this.user.impersonated){
            window.location=process.env.VUE_APP_EVLINE_URL
          }else{
            window.location = "/login"
          }
        }
      })
    }
  }, created() {
    // dont expand if screen is small
    if (window.innerWidth < 1024) {
      this.expanded = false;
    }
  }
}
</script>
  